var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-3",attrs:{"no-body":""}},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',{attrs:{"sm":"12","md":"7"}},[_c('validation-observer',{ref:"formRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataTax.singular.name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tax-name","state":errors.length > 0 ? false:null,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataTax.placeholder.name') : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataTax.singular.rate'),"label-for":"rate"}},[_c('validation-provider',{attrs:{"rate":"rate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"tax-rate","state":errors.length > 0 ? false:null,"options":_vm.numeric,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataTax.singular.rate') : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.rate),callback:function ($$v) {_vm.$set(_vm.form, "rate", _vm._n($$v))},expression:"form.rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataTax.singular.coa'),"label-for":"coa"}},[_c('validation-provider',{attrs:{"name":"account_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"account_name","options":_vm.LOV.account,"reduce":function (field) { return field.id; },"label":"account_name","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.sell_account_id),callback:function ($$v) {_vm.$set(_vm.form, "sell_account_id", $$v)},expression:"form.sell_account_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataTax.singular.buycoa'),"label-for":"buycoa"}},[_c('validation-provider',{attrs:{"name":"account_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"account_name","options":_vm.LOV.account,"reduce":function (field) { return field.id; },"label":"account_name","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.buy_account_id),callback:function ($$v) {_vm.$set(_vm.form, "buy_account_id", $$v)},expression:"form.buy_account_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mt-3 d-flex flex-sm-column flex-md-row justify-content-between"},[_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":_vm.actions.isPreview ? 'outline-secondary' : 'outline-primary',"to":{ name: 'apps-master-data-tax-list'}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.backToList'))+" ")],1),(_vm.actions.isPreview)?_c('b-button',{staticClass:" mb-sm-1 mb-md-0",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.delete'))+" ")],1):_vm._e(),(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.handleSubmit}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.actions.isEditMode ? _vm.$t('globalActions.update') : _vm.$t('globalActions.create'))+" ")],1):_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.changeToEdit}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.changeToEdit'))+" ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }